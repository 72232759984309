<template>
  <div class="fluid" id="payrollTemplateList">
    <div>
      <div class="payroll-template-list-div">
        <v-card elevation="0" class="px-3 py-2" style="width: 100%">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h6 class="indigo--text" style="font-weight: bold; margin: auto">
                PAYROLL TEMPLATE
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="payroll-template-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-dialog v-model="dialog" width="500" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size: 12px"
                        @click="addPosition"
                        :disabled="loading"
                      >
                        Tambah Data
                      </v-btn>
                    </template>

                    <v-form
                      :disabled="loading"
                      ref="entryForm"
                      @submit.prevent="submit"
                      style="position: relative"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Tambah / Edit Template
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-row no-gutters>
                            <v-col md="12" style="padding: 0; margin-top: 15px">
                              <div style="padding: 0 10px; height: 70px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Nama Template
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-text-field
                                    dense
                                    v-model="form.name"
                                    outlined
                                    :rules="nameRules"
                                  />
                                </v-col>
                              </div>
                            </v-col>
                            <v-col md="12" style="padding: 0; margin-top: 15px">
                              <div style="padding: 0 10px; height: 70px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Code
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-text-field
                                    dense
                                    v-model="form.code"
                                    outlined
                                    :rules="codeRules"
                                  />
                                </v-col>
                              </div>
                            </v-col>
                            <v-col md="12" style="padding: 0; margin-top: 15px">
                              <div style="padding: 0 10px; height: 70px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Perusahaan
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-autocomplete
                                    v-model="form.company"
                                    :items="dropdown.company"
                                    :rules="companyRules"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    outlined
                                    dense
                                    clearable
                                    style="margin: 0"
                                  />
                                </v-col>
                              </div>
                            </v-col>
                            <v-col md="12" style="padding: 0; margin-top: 15px">
                              <div style="padding: 0 10px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Jabatan
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-autocomplete
                                    v-model="form.employee_level_id"
                                    :items="dropdown.employee_level"
                                    chips
                                    multiple
                                    small-chips
                                    deletable-chips
                                    append-icon=""
                                    item-text="name"
                                    item-value="id"
                                    return-id
                                    outlined
                                    clearable
                                    style="margin: 0"
                                  />
                                </v-col>
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              style="margin: 5px 0; padding-left: 10px"
                            >
                              <v-row no-gutters>
                                <v-col cols="8" class="d-flex align-center">
                                  <div>Cari nama berdasarkan perusahaan ?</div>
                                </v-col>
                                <v-col class="d-flex align-center" cols="4">
                                  <v-checkbox
                                    v-model="form.is_lock"
                                    style="margin: 0; height: 25px; padding: 0"
                                    :disabled="form.company === null"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col md="12" style="padding: 0; margin-top: 15px">
                              <div style="padding: 0 10px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Dapat diakses oleh
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-autocomplete
                                    v-model="form.employee_id"
                                    :items="dropdown.employee"
                                    :search-input.sync="employeeSearch"
                                    chips
                                    multiple
                                    small-chips
                                    deletable-chips
                                    append-icon=""
                                    item-text="name"
                                    item-value="id"
                                    return-id
                                    outlined
                                    clearable
                                    cache-items
                                  >
                                    <template v-slot:item="data">
                                      <template>
                                        <div style="width: 50px">
                                          <v-list-item-content>
                                            <v-checkbox
                                              readonly
                                              :value="
                                                form.employee_id.includes(
                                                  data.item.id
                                                )
                                              "
                                              :false-value="false"
                                              :true-value="true"
                                              style="
                                                margin: 0 0 0 10px;
                                                height: 25px;
                                                padding: 0;
                                              "
                                            ></v-checkbox>
                                          </v-list-item-content>
                                        </div>
                                        <v-list-item-avatar
                                          style="margin-right: 5px"
                                        >
                                          <img :src="`${data.item.photo}`" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ data.item.name }}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                            {{ data.item.company_name }} -
                                            {{ data.item.nik }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                              </div>
                            </v-col>
                            <v-col md="12" style="padding: 0; margin-top: 5px">
                              <div style="padding: 0 10px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Komponen
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-autocomplete
                                    v-model="form.payroll_component_id"
                                    :items="dropdown.payroll_component"
                                    chips
                                    multiple
                                    small-chips
                                    deletable-chips
                                    append-icon=""
                                    item-text="name"
                                    item-value="id"
                                    return-id
                                    outlined
                                    clearable
                                    style="margin: 0"
                                  />
                                </v-col>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="close"
                            :loading="loading"
                          >
                            Batal
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            type="submit"
                            text
                            outlined
                            :loading="loading"
                          >
                            Simpan
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                  <div
                    style="width: 310px; margin-left: auto"
                    class="payroll-template-list-toolbar-1"
                  >
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="cari template"
                      type="search"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      style="position: relative; top: 15px; margin-left: 10px"
                      @keyup.enter="searchEnter"
                      :disabled="loading"
                    ></v-text-field>
                  </div>
                </v-toolbar>
                <v-toolbar
                  class="payroll-template-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div>
                    <div style="width: 120px">
                      <v-select
                        :item-disabled="disableItem"
                        label="Action"
                        style="position: relative; top: 15px; font-size: 12px"
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          //   { id: 8, name: 'Export Item Terpilih' },
                          //   { id: 9, name: 'Export Semua Item' },
                          { id: 1, name: 'Delete' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                  </div>
                  <div style="width: 210px; margin-left: 15px">
                    <v-select
                      label="Company"
                      style="
                        position: relative;
                        top: 15px;
                        margin-left: 5px;
                        font-size: 12px;
                      "
                      v-model="paramAPI.company_id"
                      :items="dropdown.company"
                      @change="companyWatcher"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      clearable
                      dense
                    ></v-select>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar
                class="payroll-template-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Cari template"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12" style="width: 100%">
              <v-data-table
                v-if="result.length > 0 || !skeleton_loading"
                mobile-breakpoint="0"
                fixed-header
                height="58vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                show-select
                :single-select="false"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                :page="paramAPI.page"
                @update:page="updatePage"
                :items-per-page="paramAPI.itemsPerPage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 180px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.code`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 75px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.code }}
                  </div>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 210px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.company_name }}
                  </div>
                </template>
                <template v-slot:[`item.employee_level_id`]="{ item }">
                  <div @click.stop style="padding: 0; width: 410px">
                    <v-autocomplete
                      v-model="item.employee_level_id"
                      :items="dropdown.employee_level"
                      readonly
                      chips
                      multiple
                      small-chips
                      append-icon=""
                      item-text="name"
                      item-value="id"
                      return-id
                    />
                  </div>
                </template>
                <template v-slot:[`item.employee_id`]="{ item }">
                  <div @click.stop style="padding: 0; width: 410px">
                    <v-autocomplete
                      v-model="item.employee_id"
                      :items="dropdown.employee_list"
                      readonly
                      chips
                      multiple
                      small-chips
                      append-icon=""
                      item-text="name"
                      item-value="id"
                      return-id
                    />
                  </div>
                </template>
                <template v-slot:[`item.payroll_component_id`]="{ item }">
                  <div @click.stop style="padding: 0; width: 410px">
                    <v-autocomplete
                      v-model="item.payroll_component_id"
                      :items="dropdown.payroll_component"
                      readonly
                      chips
                      multiple
                      small-chips
                      append-icon=""
                      item-text="name"
                      item-value="id"
                      return-id
                    />
                  </div>
                </template>
                <!-- <template v-slot:[`item.createdAt`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                      width: 80px;
                    "
                  >
                    {{ convertDate(item.createdAt) }}
                  </div>
                </template> -->
              </v-data-table>
              <v-skeleton-loader
                v-else
                style="width: 100%"
                type="table-heading, table-tbody, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations /*  mapActions */ } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  name: 'position',
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    oAuth: buildType.apiURL('oauth'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      itemsPerPage: 10,
      company_id: null
    },
    totalData: 0,
    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'Nama Template',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Code',
        value: 'code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Jabatan',
        value: 'employee_level_id',
        align: 'left',
        sortable: false
      },
      {
        text: 'Dapat diakses oleh',
        value: 'employee_id',
        align: 'left',
        sortable: false
      },
      {
        text: 'Komponen',
        value: 'payroll_component_id',
        align: 'left',
        sortable: false
      }
      //   {
      //     text: 'Tgl. Buat',
      //     value: 'createdAt',
      //     align: 'left',
      //     sortable: false
      //   }
      // {
      //   text: 'Dibuat Oleh',
      //   value: 'created_by_name',
      //   align: 'left',
      //   sortable: false
      // }
    ],
    result: [],
    selectedData: [],
    loading: false,
    form: {
      act: 'add',
      id: '',
      name: '',
      code: '',
      company: null,
      employee_level_id: [],
      employee_id: [],
      payroll_component_id: [],
      is_lock: true
    },
    nameRules: [],
    codeRules: [],
    companyRules: [],
    employeeSearch: null,
    selectEmployee: null,
    dropdown: {
      employee_level: [],
      employee: [],
      employee_list: [],
      company: [],
      payroll_component: []
    },
    skeleton_loading: true
  }),

  async mounted() {
    // this.paramAPI.company_id = this.getUserProfile.employee.company.id
    if (this.getPayrollTemplatePageQuery !== null) {
      this.paramAPI = this.getPayrollTemplatePageQuery
    }
    setTimeout(async () => {
      this.initDropdown()
      await this.getDataFromApi()
      this.setAction()
    }, 200)
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getDropdownPlant',
      'getPayrollTemplatePageQuery'
    ])
  },
  watch: {
    employeeSearch(val) {
      if (val !== null) {
        if (val !== '') {
          val && val !== this.selectEmployee && this.querySelections(val)
        } else {
          this.selectEmployee = null
          this.dropdown.employee = []
        }
      } else {
        this.selectEmployee = null
        this.dropdown.employee = []
      }
    }
  },
  methods: {
    ...mapMutations(['setPayrollTemplatePageQuery']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    async companyWatcher(p) {
      this.resetSomeParam()
      await this.getDataFromApi()
    },
    imgError(xItem) {
      const xId = xItem.id
      const xIndex = this.dropdown.employee.findIndex((item) => item.id === xId)
      this.dropdown.employee[xIndex].photo =
        'https://cdn-icons-png.flaticon.com/512/61/61173.png'
    },
    querySelections(v) {
      console.log(v)
      let url = `${this.hrsApi}employee/dropdown?keyword=${v}`

      if (this.form.company !== null) {
        if (this.form.is_lock) {
          url = url + `&company_id=${this.form.company.id}`
        }
      }
      axios
        .get(url)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: Number(res.data.data[i].id),
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            return (this.dropdown.employee = resArr)
          }
          return (this.dropdown.employee = [])
        })
        .catch((err) => {
          this.dropdown.employee = []
          return console.log(err)
        })
    },

    getDropdownEmployeeList() {
      const url = `${this.hrsApi}employee/dropdown?keyword=`
      axios
        .get(url)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: Number(res.data.data[i].id),
                name: res.data.data[i].name
              })
            }
            return (this.dropdown.employee_list = resArr)
          }
          return (this.dropdown.employee_list = [])
        })
        .catch((err) => {
          this.dropdown.employee_list = []
          return console.log(err)
        })
    },
    initDropdown() {
      this.getDropdownEmployeeList()
      this.getDropdownCompany()
      this.getDropdownEmployeeLevel()
      this.getDropdownPayrollComponent()
    },
    async getDropdownPayrollComponent() {
      await axios
        .get(`${this.hrsApi}master/universal/payrollcomponent/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            console.log('payroll_component>>>>', res)
            return (this.dropdown.payroll_component = res.data.data)
          }
          return (this.dropdown.payroll_component = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.payroll_component = [])
        })
    },
    async getDropdownEmployeeLevel() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            console.log('company>>>>', res)
            return (this.dropdown.employee_level = res.data.data)
          }
          return (this.dropdown.employee_level = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.employee_level = [])
        })
    },
    async getDropdownCompany() {
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          console.log('company>>>>', res)
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    close() {
      this.nameRules = []
      this.form = {
        act: 'add',
        id: '',
        name: '',
        code: '',
        company: null,
        employee_level_id: [],
        employee_id: [],
        payroll_component_id: []
      }
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.selectedData.length < 2) {
            this.delete()
          } else {
            this.showMsgDialog(
              'warning',
              'Maaf, untuk saat ini Anda hanya dapat menghapus 1 data pada satu waktu',
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = null
            }, 200)
          }
          break
        // case 8:
        //   this.exportItem()
        //   break
        // case 9:
        //   this.exportItem()
        //   break
      }
    },
    searchEnter() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      let url = `${this.hrsApi}master/universal/payrolltemplate/list?keyword=${
        this.paramAPI.keyword
      }&order_by=${this.paramAPI.sortBy}&order_type=${
        this.paramAPI.sortType
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }`

      const filter = []
      if (this.paramAPI.company_id !== null) {
        filter.push(`{"company_id": ${this.paramAPI.company_id}}`)
      }
      url = url + `&filter=[${filter}]`
      this.setPayrollTemplatePageQuery(this.paramAPI)
      return await new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            console.log('response>>>', res)
            if (res.data.status_code == '00') {
              // const arrUserLevel = []
              //   console.log('user_level>>', res.data.data[0].user_level_id)
              resolve(res.data)
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addPosition() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    rowClick(pItem) {
      console.log(pItem)
      this.querySelections('')
      this.form.act = 'update'
      this.form.id = pItem.id
      this.form.name = pItem.name
      this.form.code = pItem.code
      if (pItem.company_id !== null) {
        this.form.company = {
          id: pItem.company_id,
          name: pItem.company_name
        }
      }
      this.form.employee_id = pItem.employee_id
      this.form.employee_level_id = pItem.employee_level_id
      this.form.payroll_component_id = pItem.payroll_component_id
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    async delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.hrsApi}master/universal/payrolltemplate/delete/${this.selectedData[0].id}`
            )
            .then(async (res) => {
              this.setAction()
              this.showMsgDialog('success', res.data.status_msg, 'false')
              await this.getDataFromApi()
            })
            .catch((err) => {
              console.log(err)
            })
          this.loading = false
        } else {
          this.actionValue = 0
        }
      })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },

    submit() {
      this.nameRules = [(v) => !!v || 'Name is required']
      this.codeRules = [(v) => !!v || 'Code is required']
      this.companyRules = [(v) => !!v || 'Company is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        name: this.form.name,
        code: this.form.code,
        employee_id: this.form.employee_id,
        employee_level_id: this.form.employee_level_id,
        payroll_component_id: this.form.payroll_component_id
      }
      if (this.form.company !== null) {
        Object.assign(form, {
          company_id: this.form.company.id,
          company_name: this.form.company.name
        })
      }
      //   if (this.form.user_level_id !== null) {
      //     Object.assign(form, { user_level_id: this.form.user_level_id })
      //   }
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      console.log('>>>>Form', form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}master/universal/payrolltemplate/save`, form)
        .then(async (res) => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            await this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    getDropdownUserLevel() {
      axios
        .get(
          `${this.oAuth}user_level/drop_down?keyword=&application_id=&order_by=id&order_type=asc`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            const newArr = []
            const response = res.data.data
            for (let i = 0; i < res.data.data.length; i++) {
              newArr.push({
                id: parseInt(response[i].id),
                name: response[i].name
              })
            }
            return (this.dropdown.user_level = newArr)
          }
          return (this.dropdown.user_level = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.user_level = [])
        })
    },
    disableItem(item) {
      if (item.id !== 9) {
        return this.selectedData.length === 0
      }
      if (item.id === 9) {
        return this.selectedData.length !== 0
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJB'
        default:
          return '-'
      }
    }
  }
}
</script>
<style lang="scss">
#payrollTemplateList {
  position: relative;
  .payroll-template-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .payroll-template-list-toolbar-1 {
      .payroll-template-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .payroll-template-list-toolbar-2 {
      display: none;
    }
    .dropdown-user-level {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  #payrollTemplateList {
    .payroll-template-list-div {
      .payroll-template-list-toolbar-1 {
        .payroll-template-list-toolbar-1 {
          display: none;
        }
      }
      .payroll-template-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
